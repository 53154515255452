import {IBonusCard, IProfileBonusCard} from "./interface";
import moment from 'moment';
import {City} from "../../../entities";

export class BonusCardModel {
  id?: number;
  bonusImage?: string;
  bonusImageFull?: string;
  bonusName?: string;
  dateValidity?: string;
  isTaken?: boolean;
  cities?: City[];
  partnerName?: string;
  partnerImage?: string;
  partnerUrl?: string;
  dateValidityBeautify?: string;
  status?: string;
  bonusConfines?: string;
  bonusDescription?: string;
  promocode?: string;
  state?: number;
  stateName?: string;
  rawBonusCard?: IBonusCard;
  w?: number;
  h?: number;
  isExpired: boolean;
  hasFeedback?: boolean;
  private readonly getDateFullFormat?: (date?: string) => (string);
  private readonly getStateName?: (state?: number) => (string);

  constructor(bonusCard: IBonusCard) {

    this.getDateFullFormat = (date?: string) => {
      let momentDate = moment(date);
      let momentDateToDate = momentDate.toDate();

      if (momentDateToDate.getFullYear() > new Date().getFullYear()) {
        return momentDate.format('D MMMM YYYY')
      }
      return momentDate.format('D MMMM YYYY')
    }

    this.getStateName = (state?: number): string => {
      if (state === 1)
        return 'UnAuthorized'
      if (state === 2)
        return 'NoDonations'
      if (state === 3)
        return 'CanTake'
      if (state === 4)
        return 'AlreadyTaken'
      if (state === 5)
        return 'BonusesEnded'
    }

    this.rawBonusCard = bonusCard;
    this.id = bonusCard?.id;
    this.bonusImageFull = bonusCard?._bonus_image;
    this.bonusImage = bonusCard?.bonus_image;
    this.bonusName = bonusCard?.bonus_name;
    this.dateValidity = bonusCard?.date_validity;
    this.dateValidityBeautify = `до ${this.getDateFullFormat(bonusCard?.date_validity!)}`;
    this.isTaken = bonusCard?.is_taken;
    this.partnerName = bonusCard?.partner_name;
    this.partnerImage = bonusCard?.partner_image;
    this.status = bonusCard?.is_taken ? 'accepted' : '';
    this.partnerUrl = bonusCard?.partner_url;
    this.bonusConfines = bonusCard?.bonus_confines;
    this.bonusDescription = bonusCard?.bonus_description;
    this.promocode = bonusCard?.promocode;
    this.state = bonusCard?.state;
    this.stateName = this.getStateName(bonusCard?.state!);
    this.w = bonusCard?.w;
    this.h = bonusCard?.h;
    this.cities = bonusCard?.cities;
    this.isExpired = bonusCard?.is_expired;
    this.hasFeedback = bonusCard?.has_feedback;
  }
}

export class ProfileBonusCardModel {
  taken?: IBonusCard[];
  private readonly getBeautifiedData?: (data?: IBonusCard[]) => (BonusCardModel[]);

  constructor(bonuses: IBonusCard[]) {

    this.getBeautifiedData = (data?: IBonusCard[]): BonusCardModel[] => data?.map(bonus => new BonusCardModel(bonus))

    this.taken = this.getBeautifiedData(bonuses);
  }

}
